import i18next, { t } from 'i18next';
import toastr from 'toastr';

import { isHTMLElement } from '@utils/elementTypeGuards';
import getKakaoEscapeUrl from '@utils/getKakaoEscapeUrl';
import getAndroidEscapeUrl from '@utils/getAndroidEscapeUrl';
import { isAndroid, isMobile, isIOS, isKAKAO } from '@utils/uaParser';
import debounce from '@utils/debounce';

const backdrop = document.querySelector('#backdrop');
const bottomSheet = document.querySelector('#bottom-sheet');
const bottomSheetTitle = document.querySelector('#bottom-sheet-title');
const bottomSheetDesc = document.querySelector('#bottom-sheet-description');
const bottomSheetCloseBtn = document.querySelector('.bottom-sheet-close-btn');
const iosDefaultBrowserBtn = document.querySelector('#ios-default-browser-btn');
const linkCopyBtn = document.querySelector('#link-copy-btn');
const bottomSheetChromeBtn = document.querySelector('#chrome-connect-btn');
const bottomSheetIosDefaultBrowserBtn = document.querySelector(
  '#ios-default-browser-btn',
);
const bottomSheetCopyLinkBtn = document.querySelector('#link-copy-btn');
const samsungBrowserImg = document.querySelector(
  '#samsung-browser-email-warning',
);
const samsungBrowserImgContainer = document.querySelector(
  '#samsung-browser-email-warning-container',
);
toastr.options = {
  closeButton: false,
  newestOnTop: false,
  positionClass: 'toast-bottom-center',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

const currentUrlWithoutProtocol =
  window.location.hostname +
  window.location.pathname +
  window.location.search +
  window.location.hash;

const debounceBottomSheetClose = debounce(() => {
  if (isHTMLElement(bottomSheet)) {
    bottomSheet.style.visibility = 'hidden';
  }
}, 300);

const embeddedSheetClickHandler = () => {
  if (
    isHTMLElement(backdrop) &&
    isHTMLElement(bottomSheet) &&
    isHTMLElement(bottomSheetTitle) &&
    isHTMLElement(bottomSheetDesc) &&
    isHTMLElement(bottomSheetCloseBtn) &&
    isHTMLElement(iosDefaultBrowserBtn) &&
    isHTMLElement(linkCopyBtn) &&
    isHTMLElement(bottomSheetChromeBtn) &&
    isHTMLElement(bottomSheetIosDefaultBrowserBtn) &&
    isHTMLElement(bottomSheetCopyLinkBtn)
  ) {
    bottomSheetTitle.textContent = i18next.t(
      'bottomSheet.embeddedBrowser.title',
    );
    bottomSheetDesc.textContent = i18next.t(
      'bottomSheet.embeddedBrowser.description',
    );
    bottomSheetCloseBtn.textContent = i18next.t(
      'bottomSheet.embeddedBrowser.btn.close',
    );

    if (isAndroid && isMobile) {
      bottomSheetChromeBtn.style.display = 'block';
    } else if (isIOS && isMobile && isKAKAO) {
      iosDefaultBrowserBtn.style.display = 'block';
    }

    const addTextNode = (element: Element, text: string) => {
      const textNode = document.createTextNode(text);
      element.appendChild(textNode);
    };

    addTextNode(
      bottomSheetChromeBtn,
      i18next.t('bottomSheet.embeddedBrowser.btn.chrome'),
    );
    addTextNode(
      bottomSheetIosDefaultBrowserBtn,
      i18next.t('bottomSheet.embeddedBrowser.btn.defaultBrowser'),
    );
    addTextNode(
      bottomSheetCopyLinkBtn,
      i18next.t('bottomSheet.embeddedBrowser.btn.copyLink'),
    );

    const bottomShitCloseBtnHandler = () => {
      bottomSheet.style.transform = 'translateY(100%)';
      backdrop.style.display = 'none';
      debounceBottomSheetClose();
    };

    bottomSheetCloseBtn.addEventListener('click', bottomShitCloseBtnHandler);
    bottomSheetChromeBtn.addEventListener('click', () => {
      location.href = getAndroidEscapeUrl(currentUrlWithoutProtocol);
    });
    iosDefaultBrowserBtn.addEventListener('click', () => {
      location.href = getKakaoEscapeUrl(window.location.href);
    });
    linkCopyBtn.addEventListener('click', () => {
      toastr.info(t('index.notification.linkCopy'));
      navigator.clipboard.writeText(window.location.href);
      bottomShitCloseBtnHandler();
    });
    backdrop.addEventListener('click', bottomShitCloseBtnHandler);
  }
};

const samsungSheetClickHandler = () => {
  if (
    isHTMLElement(backdrop) &&
    isHTMLElement(bottomSheet) &&
    isHTMLElement(bottomSheetTitle) &&
    isHTMLElement(bottomSheetDesc) &&
    isHTMLElement(bottomSheetCloseBtn) &&
    isHTMLElement(iosDefaultBrowserBtn) &&
    isHTMLElement(linkCopyBtn) &&
    isHTMLElement(bottomSheetChromeBtn) &&
    isHTMLElement(bottomSheetIosDefaultBrowserBtn) &&
    isHTMLElement(samsungBrowserImg) &&
    isHTMLElement(samsungBrowserImgContainer)
  ) {
    bottomSheetTitle.textContent = i18next.t(
      'bottomSheet.samsungBrowser.title',
    );
    bottomSheetDesc.textContent = i18next.t(
      'bottomSheet.samsungBrowser.description',
    );
    bottomSheetCloseBtn.textContent = i18next.t(
      'bottomSheet.samsungBrowser.btn.close',
    );

    samsungBrowserImg.style.display = 'block';
    samsungBrowserImgContainer.style.display = 'block';

    const bottomShitCloseBtnHandler = () => {
      bottomSheet.style.transform = 'translateY(100%)';
      backdrop.style.display = 'none';
      debounceBottomSheetClose();
    };

    bottomSheetCloseBtn.addEventListener('click', bottomShitCloseBtnHandler);
    bottomSheetChromeBtn.addEventListener('click', () => {
      location.href = getAndroidEscapeUrl(currentUrlWithoutProtocol);
    });
    iosDefaultBrowserBtn.addEventListener('click', () => {
      location.href = getKakaoEscapeUrl(window.location.href);
    });

    backdrop.addEventListener('click', bottomShitCloseBtnHandler);

    linkCopyBtn.style.display = 'none';
  }
};

let sheetType: 'embeddedBrowser' | 'samsungBrowser';

const showBottomSheet = (type: 'embeddedBrowser' | 'samsungBrowser') => {
  sheetType = type;
  if (sheetType === 'embeddedBrowser') {
    embeddedSheetClickHandler();
  } else if (sheetType === 'samsungBrowser') {
    samsungSheetClickHandler();
  }

  if (isHTMLElement(bottomSheet) && isHTMLElement(backdrop)) {
    backdrop.style.display = 'block';
    bottomSheet.style.visibility = 'visible';
    bottomSheet.style.transform = 'translateY(0)';
    debounceBottomSheetClose.cancel();
  }
};

export { showBottomSheet };
